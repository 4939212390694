<template>
	<div class="music-blk">
		<!-- <audio src="@/assets/audio/temp.mp3" 
			ref="audio"
			:lood="activeData.play_mode==0" 
			autoplay 
			controls 
			v-if="activeData.file_list.length>0"></audio> -->
	</div>
</template>
<script>
import {mapState} from 'vuex'
export default {
	components: {
	},
	name: 'musicWindow',
	props: {
		winData: Object,
		scale: Number
	},
	data() {
		return {
			activeData: {},
			audio: null,
			currentIndex: 0,
			isPlaying: false
		}
	},
	computed: {
		...mapState({
            programPlaying: state => state.app.programPlaying,
            programPlayMs: state => state.app.programPlayMs,
            programVideoPlaying: state => state.app.programVideoPlaying
        }),
        startTimeMs () {
            return this.activeData.start_time*1000
        },
        endTimeMs () {
            // console.log('datetime end',this.activeData.end_time)
            return this.activeData.end_time*1000
        }
	},
	watch: {
		programPlayMs (value) {
			if(this.activeData.file_list.length==0) return
			if(value > this.startTimeMs && value<this.endTimeMs){
				if(!this.isPlaying){
					this.playSound(0)
				}
			}else if(this.isPlaying){
				this.stopPlaySound()
			}
		},
        programPlaying (isPlaying) {
			if(this.activeData.file_list.length==0) return
            if(this.programPlayMs>=this.startTimeMs && this.programPlayMs<=this.endTimeMs){
                if(isPlaying){
                    this.playSound(0)
                }else{
                    this.stopPlaySound()
                }
            }else{
				this.stopPlaySound()
			}
        },
		programVideoPlaying () {
			this.setMuted()
		}
	},
	created() {
		this.activeData = this.winData
		this.render()
	},
	destroyed () {
		console.log('music destroyed')
		this.stopPlaySound()
	},
	methods: {
		render () {
			// console.log('render music window')
			if(this.audio){
				this.audio.pause()
			}
			if(this.activeData.file_list.length>0){
				this.playSound(0)
			}else if(this.audio){
				this.audio.src = ""
			}
		},
		setMuted () {
			if(this.audio) {
				this.audio.muted = this.programVideoPlaying
			}
		},
		playSound(index){
			if(!this.activeData.file_list[index]) return
			if(this.programPlayMs<this.startTimeMs ||  this.programPlayMs>this.endTimeMs) return
			this.isPlaying = true
			if(!this.audio){
				this.audio = document.createElement('audio');
			    //是否进行预加载
			    this.audio.preload = 'auto'
				this.audio.autoplay = false
				this.audio.loop = false
			    //是否显示隐藏按钮
			    this.audio.controls = true;
			    this.audio.hidden = true;
			    this.audio.muted = true;
		    	//绑定音乐结束事件，当音乐播放完成后，将会触发playEndedHandler方法
			    this.audio.addEventListener("ended",this.playEndedHandler,false);
			    this.audio.addEventListener('error', ()=>{
			    	console.log('error')
			    	this.audio.play();
			    }, false)
			    this.audio.addEventListener("canplay",()=>{
					if(this.programPlaying){
						this.audio.play();
					}
			    	
			    	// console.log('正在播放->'+this.audio.src)
			    }, false)
		    	document.body.appendChild(this.audio)
			}

		    this.audio.src = this.activeData.file_list[index].file_url
			
			this.audio.pause()
			this.audio.currentTime = 0
			// console.log('this.activeData.first_mode',this.activeData.first_mode)
			this.setMuted()
			if(this.programPlaying){
				this.audio.play();
				this.$store.commit('app/setProgramMusicPlaying',true)
			}
			this.currentIndex = index
			// console.log('this.audio.muted',this.audio.muted)
		},
		// 监听每个音频播放完毕
		playEndedHandler(){
			let len = this.activeData.file_list.length
			if(this.currentIndex<len-1){
				this.playSound(this.currentIndex+1)
			}else if(this.activeData.play_mode==0){
				this.playSound(0)
			}
	    },
		stopPlaySound () {
			if(this.audio){
				this.audio.pause()
			}
			
			this.isPlaying = false
			this.$store.commit('app/setProgramMusicPlaying',false)
		}
	},
	mounted() {},
}
</script>