<template>
    <div>
        <!-- 基础属性 -->
        <window-attr @update="$emit('update:list')" :winData="winData" />
        <!-- 视频列表及属性 -->
        <b-card  class="shadow-none bg-transparent border">
            <div class="d-flex flex-wrap align-items-center mb-1">
                <feather-icon
                    icon="ListIcon"
                    class="cursor-pointer"
                    size="16"
                    @click="deleteAction(data.item)"
                />
                <h5 class="ml-25 mb-0 text-nowrap">{{$t('Materials And Attributes')}}</h5>
                <b-button class="ml-1" variant="primary" size="sm" @click="selectMaterial">{{$t('Select Video')}}</b-button>
                <b-button class="ml-1" variant="outline-primary" size="sm" @click="uploadMaterial">
                    {{$t('Upload Video')}}
                </b-button>
            </div>
            <template v-if="mediaList.length>0">
                <draggable
                    class="media-list"
                    v-model="mediaList"
                    v-bind="dragOptions"
                    @start="isDragging = true"
                    @end="isDragging = false"
                >
                    <div class="media-item" 
                        :class="{active:mediaIndex==activeIndex}"
                        v-for="(mediaItem,mediaIndex) in mediaList"
                        :key="mediaIndex"
                        @click="chooseMedia(mediaIndex)">
                        <video class="video" loop="loop" muted draggable="false" :src="mediaItem.file_url"></video>
                        <a class="delete" href="javascript:;" @click.stop="deleteMedia(mediaIndex)">×</a>
                    </div>
                </draggable>
                <div class="splitter my-3"></div>
            </template>
            <div class="grey--text py-4 text-center" v-else>{{$t('No Material')}}</div>
            <b-row v-if="activeMedia">
                <!-- 音量 -->
                <b-col :cols="12" :md="4">
                    <b-form-group :label="$t('Volumn')">
                        <b-form-spinbutton
                            v-model="activeMedia.volume"
                            inline
                            min="1"
                            max="100"
                            step="10"
                        />
                    </b-form-group>
                </b-col>
                <!-- 播放次数 -->
                <b-col :cols="12" :md="4">
                    <b-form-group :label="$t('Play Number')">
                        <b-form-input
                            v-model="activeMedia.play_number"
                            trim
                            :placeholder="$t('Please Enter...')"
                            @input="$emit('update:list')"
                        />
                    </b-form-group>
                </b-col>
                <!-- 播放时长 -->
                <b-col :cols="12" :md="4">
                    <b-form-group :label="$t('Play Duration')">
                        <b-input-group append="s" class="input-group-merge">
                            <b-form-input
                                v-model="activeMedia.play_times"
                                trim
                                type="number"
                                :placeholder="$t('Please Enter...')"
                                disabled
                            />
                        </b-input-group>
                    </b-form-group>
                </b-col>
            </b-row>
        </b-card>
        <MaterialSelect :type="1" v-model="selectSidebarVisible" v-if="selectSidebarVisible" @select="onSelectMaterial" />
		<!-- upload -->
		<upload
			v-if="uploadModalVisible"
			:is-active.sync="uploadModalVisible"
            :type="1"
			@refetch-data="addFileList"
		></upload>
    </div>
</template>
<script>
import { BCard, BCardText, BRow, BCol,BButton, BFormInput, BBadge, BTabs, BTab,BFormGroup, BInputGroup, BFormSpinbutton } from 'bootstrap-vue'
import draggable from "vuedraggable";
import vSelect from 'vue-select'
import { VueColorpicker } from 'vue-colorpicker-pop'
import WindowAttr from "./WindowAttr.vue"
import MaterialSelect from "./MaterialSelect.vue"
import Upload from "@/components/material/Upload.vue"
import {fullMaterialUrl} from "@/libs/utils"
export default {
	components: {
		BCard,
		BCardText,
		BRow, BCol,
		BButton,
		BFormInput,
		BBadge, BTabs, BTab,
        BFormGroup,BInputGroup, BFormSpinbutton,
        draggable,
        vSelect,
        'color-picker': VueColorpicker,
        WindowAttr,
        MaterialSelect,
        Upload
	},
    props: {
        winData: {
            type: Object,
            default: {}
        }
    },
	data () {
		return {
			mediaList: [],
            activeIndex: -1,
            isDragging: false,
            activeData: {},
            dragOptions: {
                animation: 300,
                group: "description",
                disabled: false,
                ghostClass: "ghost"
            },
            selectSidebarVisible: false,
            uploadModalVisible: false
		}
	},
    computed: {
        activeMedia () {
            if(this.activeIndex == -1) return null
            return this.mediaList[this.activeIndex]
        }
    },
    created () {
        this.activeData = this.winData
        this.mediaList = this.activeData.file_list
        if(this.mediaList.length>0){
            this.activeIndex = 0
        }
    },
    methods: {
        fileChange (e) {
            console.log(e.target.files)
            this.addMedia()
        },
        uploadMaterial () {
            this.uploadModalVisible = true
        },
        addFileList (fileList) {
            fileList.forEach(item=>{
                this.addMedia(item)
            })
        },
        selectMaterial () {
            this.selectSidebarVisible = true
        },
        onSelectMaterial (item) {
            console.log(item)
            this.addMedia(item)
        },
        addMedia (item) {
            this.mediaList.push({
                type:0, // 0 本地 1 网络视频
                file_path: '',
                file_md5: item.md5,
                file_url: fullMaterialUrl(item.md5,item.suffix),
                volume: 50,
                play_number: 1,
                play_times: item.hour
            })
            // 网络视频有以下属性
                // url: ''
            this.activeIndex = this.mediaList.length-1
            this.$emit('update:list')
        },
        deleteMedia (index) {
            this.mediaList.splice(index,1)
            if(this.mediaList.length==0){
                this.activeIndex = -1
            }
            this.$emit('update:list', this.mediaList)
        },
        chooseMedia (index) {
            this.activeIndex = index
        }
    }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
