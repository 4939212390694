<template>
    <div>
        <!-- 基础属性 -->
        <window-attr @update="$emit('update:list')" :winData="winData" />
        <!-- 视频列表及属性 -->
        <b-card  class="shadow-none bg-transparent border">
            <div class="d-flex flex-wrap align-items-center mb-1">
                <feather-icon
                    icon="ListIcon"
                    class="cursor-pointer"
                    size="16"
                />
                <h5 class="ml-25 mb-0 text-nowrap">{{$t('Materials And Attributes')}}</h5>
                <b-button class="ml-1" variant="primary" size="sm" @click="selectMaterial">
                    {{$t('Select Music')}}
                </b-button>
                <b-button class="ml-1" variant="outline-primary" size="sm" @click="uploadMaterial">
                    {{$t('Upload Music')}}
                </b-button>
            </div>
            <b-list-group>
                <b-list-group-item v-for="(item,index) in mediaList" :key="index">
                    <div class="d-flex py-1 align-items-center">
                        <a :href="item.file_url" target="_target" class="flex-1">{{item.file_url}}</a>
                        <feather-icon
                            :id="`delete-music-icon-${index}`"
                            icon="Trash2Icon"
                            class="cursor-pointer"
                            size="16"
                            @click="deleteMedia(index)"
                        />
                        <b-tooltip
                            :title="$t('Delete')"
                            class="cursor-pointer"
                            :target="`delete-music-icon-${index}`"
                        />
                    </div>
                    <b-row v-if="activeMedia">
                        <!-- 播放次数 -->
                        <b-col :cols="12" :md="4">
                            <b-form-group :label="$t('Play Number')">
                                <b-form-input
                                    v-model="activeMedia.play_number"
                                    trim
                                    :placeholder="$t('Please Enter...')"
                                    @input="$emit('update:list')"
                                />
                            </b-form-group>
                        </b-col>
                        <!-- 播放时长 -->
                        <b-col :cols="12" :md="4">
                            <b-form-group :label="$t('Play Duration')">
                                <b-input-group append="s" class="input-group-merge">
                                    <b-form-input
                                        v-model="activeMedia.play_times"
                                        trim
                                        type="number"
                                        :placeholder="$t('Please Enter...')"
                                        disabled
                                    />
                                </b-input-group>
                            </b-form-group>
                        </b-col>
                    </b-row>
                </b-list-group-item>
            </b-list-group>
            <div class="grey--text py-4 text-center" v-if="mediaList.length==0">{{$t('No Material')}}</div>
            
        </b-card>
        <MaterialSelect :type="3" v-model="selectSidebarVisible" v-if="selectSidebarVisible" @select="onSelectMaterial" />
        <!-- upload -->
		<upload
			v-if="uploadModalVisible"
			:is-active.sync="uploadModalVisible"
            :type="3"
			@refetch-data="addFileList"
		></upload>
    </div>
</template>
<script>
import { BCard, BCardText, BRow, BCol,BButton, BFormInput, BBadge, BTabs, BTab, BListGroup,BListGroupItem, BFormGroup, BInputGroup, BFormSpinbutton,BTooltip } from 'bootstrap-vue'
import draggable from "vuedraggable";
import vSelect from 'vue-select'
import { VueColorpicker } from 'vue-colorpicker-pop'
import WindowAttr from "./WindowAttr.vue"
import MaterialSelect from "./MaterialSelect.vue"
import Upload from "@/components/material/Upload.vue"
import {fullMaterialUrl} from "@/libs/utils"
export default {
	components: {
		BCard,
		BCardText,
		BRow, BCol,
		BButton,
		BFormInput,
		BBadge, BTabs, BTab,
        BFormGroup,BInputGroup, BFormSpinbutton, BListGroup,BListGroupItem, BTooltip,
        draggable,
        vSelect,
        'color-picker': VueColorpicker,
        WindowAttr,
        MaterialSelect,
        Upload
	},
    props: {
        winData: {
            type: Object,
            default: {}
        }
    },
    computed: {
        activeMedia () {
            if(this.activeIndex == -1) return null
            return this.mediaList[this.activeIndex]
        }
    },
	data () {
		return {
			mediaList: [],
            activeIndex: -1,
            isDragging: false,
            dragOptions: {
                animation: 300,
                group: "description",
                disabled: false,
                ghostClass: "ghost"
            },
            selectSidebarVisible: false,
            uploadModalVisible: false
		}
	},
    created () {
        this.mediaList = this.winData.file_list
        if(this.mediaList.length>0){
            this.activeIndex = 0
        }
    },
    methods: {
        uploadMaterial () {
            this.uploadModalVisible = true
        },
        addFileList (fileList) {
            fileList.forEach(item=>{
                this.addMedia(item)
            })
        },
        selectMaterial () {
            this.selectSidebarVisible = true
        },
        onSelectMaterial (item) {
            this.addMedia(item)
        },
        addMedia (item) {
            this.mediaList.push({
                file_path: '',
                file_md5: item.md5,
                file_url: fullMaterialUrl(item.md5,item.suffix),
                play_number: 1,
                play_times: item.hour
            })
            this.activeIndex = this.mediaList.length-1
            this.$emit('update:list')
        },
        deleteMedia (index) {
            this.mediaList.splice(index,1)
            if(this.mediaList.length==0){
                this.activeIndex = -1
            }
            this.$emit('update:list')
        },
        chooseMedia (index) {
            this.activeIndex = index
        }
    }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
