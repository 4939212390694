<template>
    <div class="video-blk" :style="style">
        <video ref="video" 
            class="video" 
            loop="loop" 
            :muted="programMusicPlaying && musicData.first_mode==0"
            draggable="false"
            :src="videoData.file_url"
            v-if="videoData.file_url"
            :style="opacityStyle"
        ></video>
    </div>
</template>
<script>
import {mapState} from 'vuex'
import {deepCopy} from "@/libs/utils"
export default {
	name: 'videoWindow',
    props: {
        mediaItem:Object,
        mediaIndex: Number,
        mediaList: Array,
        musicData: Object
    },
    data () {
        return {
            videoData: {},
            isAnim: false,
            visible: true,
            timer: null
        }
    },
    computed: {
		...mapState({
            programPlayMs: state => state.app.programPlayMs,
            programPlaying: state => state.app.programPlaying,
            programMusicPlaying: state => state.app.programMusicPlaying,
            programPlaySpeed: state => state.app.programPlaySpeed,
        }),
        style () {
            let videoData = this.videoData
            return {
                animationDuration:videoData.into+'ms'
            }
        },
        opacityStyle () {
            let videoData = this.videoData
            return {
                opacity:videoData.transparent/100,
            }
        },
        animClass () {
            let videoData = this.videoData
            let anim = ''+videoData.sfx
            if(anim == 0){
                anim = Math.ceil(Math.random()*6)
            }
            return config.animList[anim].class
        },
        startMs () {
            if(this.mediaIndex==0) return 0
            let ms = 0
            for(let i = 0; i < this.mediaIndex; i++){
                let item = this.mediaList[i]
                let duration = item.play_times ? item.play_times*1000 : (item.stay || 0)
                ms += duration
            }
            return ms
        },
        endMs () {
            let ms = 0
            for(let i = 0; i <= this.mediaIndex; i++){
                let item = this.mediaList[i]
                ms += (item.play_times*1000)
            }
            return ms
        }
    },
    watch: {
        programPlaying (isPlaying) {
            // console.log('media video isPlaying', isPlaying,this.programPlayMs,this.startMs,this.endMs)
            if(this.programPlayMs>=this.startMs && this.programPlayMs<=this.endMs){
                if(isPlaying){
                    this.play()
                }else{
                    this.stop()
                }
            }
        },
        programPlaySpeed () {
            if(this.programPlayMs>=this.startMs && this.programPlayMs<=this.endMs){
                if(this.programPlaying){
                    this.play()
                }
            }
        },
        programPlayMs (ms) {
            if(!this.programPlaying){
                this.setCurrentTime()
            }
        }
    },
	created () {
        this.videoData = deepCopy(this.mediaItem)
        this.$store.commit('app/setProgramVideoPlaying',true)
	},
    mounted () {
        if(this.programPlaying){
            this.play()
        }
    },
    destroyed () {
        // console.log('ProgramVideo destroyed')
        this.$store.commit('app/setProgramVideoPlaying',false)
    },
    methods: {
        render (mediaNode) {
            this.videoData = mediaNode
            this.setVolumn()
            this.isAnim = false
            this.timer = setTimeout(() => {
                this.isAnim = true
            }, 100);
        },
        play () {
            this.setVolumn()
            this.$refs.video.playbackRate = +this.programPlaySpeed
            this.$refs.video.play()
        },
        stop(){
            // console.log('stop video')
            this.$refs.video.pause()
        },
        setCurrentTime () {
            let currentTime = this.programPlayMs-this.startMs
            currentTime = Math.ceil(currentTime/1000)
            this.$refs.video.currentTime = currentTime
        },
        setVolumn () {
            let volumn = this.videoData.volume/100
            this.$refs.video.volume = volumn
        },
        reset () {
            clearTimeout(this.timer)
            this.visible = false
        }
	}
}
</script>