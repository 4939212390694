<style lang="scss" scoped>
.image-blk{
	width: 100%;
	height: 100%;
	.img{
		max-width: 100%;
		max-height: 100%;
	}
	.img-bg{
		width: 100%;
		height: 100%;
		background-repeat: repeat;
		background-position: top left;
		&.fill-x{
			background-size: 100%;
			background-position: center;
			background-repeat: no-repeat;
		}
		&.fill-y{
			background-size: auto 100%;
			background-position: center;
			background-repeat: no-repeat;
		}
	}
}
</style>
<template>
	<div :class="'image-blk ' + animClass" :style="style">
		<div
			class="d-flex align-items-center justify-centent-center"
			v-if="imgData.file_url"
			:style="{ width: realW + 'px', height: realH + 'px', zoom: scale }"
		>
			<div class="img-bg"
				:class="{
					'fill-x': imgRatio>=winRatio,
					'fill-y': imgRatio<winRatio
				}"
				:style="{
					backgroundImage:'url('+imgData.file_url+')',
					opacity:imgData.transparent/100
				}">
			</div>
			<!-- <div
				class="img-bg"
				:style="{
					backgroundImage: 'url(' + imgData.file_url + ')',
					opacity: imgData.transparent / 100,
				}"
				v-if="item.effect==0"
			></div>
			<div class="img-bg"
				:class="{
					'fill-x': imgRatio>=winRatio,
					'fill-y': imgRatio<winRatio
				}"
				:style="{
					backgroundImage:'url('+imgData.file_url+')',
					opacity:imgData.transparent/100
				}"
				v-else
			</div> -->
			<!-- <img class="img" draggable="false" :src="imgData.file_url" :style="opacityStyle" /> -->
		</div>
	</div>
</template>
<script>
import { deepCopy } from "@/libs/utils";
import config from "@/libs/config";
export default {
	name: "imageWindow",
	props: {
		mediaItem: Object,
		scale: Number,
		realW: Number,
		realH: Number,
	},
	data() {
		return {
			imgData: {},
			isAnim: false,
			visible: true,
			timer: null,
			imgRatio: 1,
		};
	},
	computed: {
		style() {
			let imgData = this.imgData;
			return {
				animationDuration: imgData.into + "ms",
			};
		},
		winRatio() {
			return this.realW / this.realH;
		},
		opacityStyle() {
			let imgData = this.imgData;
			return {
				opacity: imgData.transparent / 100,
			};
		},
		animClass() {
			let imgData = this.imgData;
			let anim = "" + imgData.sfx;
			if (anim == 0) {
				anim = Math.ceil(Math.random() * 6);
			}
			return config.animList[anim].class;
		},
	},
	methods: {
		render(mediaNode) {
			// console.log(123)
			this.imgData = mediaNode;
			this.isAnim = false;
			setTimeout(() => {
				this.isAnim = true;
			}, 100);
			if (this.imgData.effect == 1) {
				this.getImgRatio((ratio) => {
					this.imgRatio = ratio;
				});
			}
		},
		start() {
			clearTimeout(this.timer);
			this.visible = true;
			this.render(this.imgData);
			let ms = this.imgData.stay + this.imgData.into;
			// console.log(ms)
			this.timer = setTimeout(() => {
				this.end();
			}, ms);
		},
		end() {
			this.visible = false;
			this.$emit("end");
		},
		reset() {
			clearTimeout(this.timer);
			this.visible = false;
		},
		getImgRatio(cbk) {
			let img = new Image();
			img.src = this.imgData.file_url;
			img.onload = function () {
				cbk && cbk(img.width / img.height);
			};
		},
	},
	created() {
		console.log(this.realW)
		this.imgData = deepCopy(this.mediaItem);
		// console.log('imgData:',this.imgData)
		this.getImgRatio();
	},
	mounted() {},
};
</script>
