<template>
    <div>
        <div class="d-md-flex align-items-center mb-1">
			<h4 class="flex-1 mb-1 mb-sm-0">
				<feather-icon
					icon="BoxIcon"
					class="cursor-pointer"
					size="16"
					@click="deleteAction(data.item)"
				/>
				{{$t('Program Name')}}: {{programInfo.name || '/'}}（{{$t('Size')}}:{{programInfo.w || '/'}}×{{programInfo.h || '/'}}）
			</h4>
			
			<b-button variant="success" :disabled="saving" v-loading="saving" @click="saveAction()">{{$t('Save')}}</b-button>
            <b-button class="ml-1" variant="primary" :disabled="saving" @click="previewAction()">{{$t('Preview')}}</b-button>
			<b-button class="ml-1" variant="outline-secondary" :disabled="saving" @click="cancelAction()">{{$t('Go Back')}}</b-button>
		</div>
        <b-card class="main-card" v-loading="loading">
			<b-tabs 
				vertical
				pills
				content-class="col-12 col-md-10 col-lg-10"
				nav-wrapper-class="program-nav col-md-2 col-lg-2 col-12"
				nav-class="nav-left"
				v-model="activeTab">
				<b-tab v-for="(item,index) in windowList" :key="item.guid">
					<template v-slot:title>
						<feather-icon icon="VideoIcon" v-if="item.type==0" size="16" />
						<feather-icon icon="ImageIcon" v-if="item.type==1" size="16" />
						<feather-icon icon="FileTextIcon" v-if="item.type==2" size="16" />
						<feather-icon icon="MusicIcon" v-if="item.type==3" size="16" />
						{{item.name}}
					</template>
					<videoAttr v-if="item.type == 0" :winData="item" @update:list="materialUpdate(item,'video')"></videoAttr>
					<imageAttr v-if="item.type == 1" :winData="item" @update:list="materialUpdate(item,'image')"></imageAttr>
					<textAttr v-if="item.type == 2" :winData="item" @update:list="materialUpdate(item,'text')"></textAttr>
					<musicAttr v-if="item.type == 3" :winData="item" @update:list="materialUpdate(item,'music')"></musicAttr>
				</b-tab>
				<div v-if="windowList.length==0" class="no-data">请添加窗口</div>
				<template v-slot:tabs-start>
					<!-- <div class="d-flex align-items-center mb-1">
						<h6 class="flex-1 m-0">窗口列表</h6>
					</div>
					<div class="divider"></div> -->
					<div class="d-flex mb-25">
						<b-button variant="flat-primary" size="sm" :disabled="activeTab==0 || windowList.length==0" @click="moveUp">
							<feather-icon icon="ArrowUpIcon" size="16" />
						</b-button>
						<b-button variant="flat-primary" size="sm" :disabled="activeTab==3 || windowList.length==0" @click="moveDown">
							<feather-icon icon="ArrowDownIcon" size="16" /> 
						</b-button>
						<b-button variant="flat-primary" size="sm" :disabled="windowList.length==0" @click="deleteLayer">
							<feather-icon icon="Trash2Icon" size="16" />
						</b-button>
					</div>
				</template>
			</b-tabs>
			<b-dropdown class="new-window-btn" variant="flat primary" no-caret dropup offset="20">
				<template #button-content>
					<b-button variant="primary" size="lg" class="btn-icon rounded-circle">
						<feather-icon size="20" icon="PlusIcon" />
					</b-button>
				</template>
				<b-dropdown-item @click="newWindow(0)">{{$t('Video Window')}}</b-dropdown-item>
				<b-dropdown-item @click="newWindow(1)">{{$t('Image Window')}}</b-dropdown-item>
				<b-dropdown-item @click="newWindow(2)">{{$t('Text Window')}}</b-dropdown-item>
				<b-dropdown-item @click="newWindow(3)">{{$t('Music Window')}}</b-dropdown-item>
			</b-dropdown>
	    </b-card>
		<Function>
			<ul class="colors-list list-unstyled mb-0">
				<li>
					<feather-icon
						size="15"
						icon="ChevronsRightIcon"
					/>
					<span>{{$t('Function Declaration')}}1</span>
				</li>
				<li>
					<feather-icon
						size="15"
						icon="ChevronsRightIcon"
					/>
					<span>{{$t('Function Declaration')}}2</span>
				</li>
			</ul>
		</Function>
		<preview :activeData="previewData" v-if="previewVisible" @close="previewVisible=false"></preview>
    </div>
	
</template>

<script>
import { BCard, BCardText, BRow, BCol,BButton, BFormInput, BBadge, BTabs, BTab,BDropdown,BDropdownItem } from 'bootstrap-vue'
import VideoAttr from "@/components/program/VideoAttr"
import ImageAttr from "@/components/program/ImageAttr"
import TextAttr from "@/components/program/TextAttr"
import MusicAttr from "@/components/program/MusicAttr"
import Preview from "@/components/program/preview/player"
import { getProgramInfo, saveProgram } from "@/libs/apis/serverApi";
import {deepCopy} from '@/libs/utils'
export default {
	components: {
		BCard,
		BCardText,
		BRow, BCol,
		BButton,
		BFormInput,
		BBadge, BTabs, BTab,
		BDropdown,BDropdownItem,
        VideoAttr,
		ImageAttr,
		TextAttr,
		MusicAttr,
		Preview
	},
	data () {
		return {
			uuid: this.$route.query.uuid,
			loading: false,
			programInfo: {},
			programData: {},
			previewData: {},
			sizeSetStatus: 0,
			width: 1920,
			height:1080,
            activeTab: -1,
			windowList: [],
			previewVisible: false,
			saving: false
		}
	},
	watch: {
		activeTab () {
			console.log('activeTab change',this.activeTab)
		}
	},
	computed: {
		activeWindow () {
			return this.windowList[this.activeTab]
		}
	},
	created () {
		this.getData ()
	},
	methods: {
		guid() {
			return Number(Math.random().toString().substr(3, 3) + Date.now()).toString(36);
		},
		getData () {
			this.loading = true
			getProgramInfo({uuid: this.uuid}).then(res=>{
				this.loading = false
				let info = res.row
				this.programInfo = info
				if(!this.programInfo.content){
					this.programData = {
						uuid: this.uuid,
						name: info.name,
						screen_w: info.w,
						screen_h: info.h,
						screen_x: 0,
						screen_y: 0,
						windows: []
					}
				}else{
					this.programData = JSON.parse(info.content)
				}
				this.windowList = this.programData.windows
				this.sortLayer()
			},err=>{
				this.loading = false
				this.$toast(err.message)
			})
		},
		createWindow (type,zIndex) {
			const defaultAttr = {
				guid: this.guid(),
				z_index: 0,
				type: 0,
				window_name:"",
				window_border_type: 0,
				window_border_color: "#ffffff",
				start_x: 0,
				start_y: 0,
				width: 1920,
				height: 1080,
				start_time: 0,
				play_number: 1,
				window_play_times: 0
			}
			const winTypeText = ['视频窗','图片窗',"文本窗","音乐窗"]
			let program = Object.assign({},defaultAttr,{name: winTypeText[type], type, z_index: zIndex})
			if(type==2){
				program.txt_list = []
			}else{
				program.file_list = []
			}
			return program
		},
		newWindow (type) {
			let newItem = this.createWindow(type,this.windowList.length)
			this.windowList.unshift(newItem)
			this.$nextTick(()=>{
				this.activeTab = 0
			})
		},
		materialUpdate (item, type) {
			const fileList = type=='text'? item.txt_list : item.file_list
			let thisDuration = 0
			if(type=='image'){ //视频
				fileList.forEach(sitem=>{
					const stay = + sitem.stay
					const into = +sitem.into
					const number = +sitem.play_number
					thisDuration += (stay + into) * number
				})
			}else{
				fileList.forEach(sitem=>{
					const time = +sitem.play_times
					const number = +sitem.play_number
					thisDuration += (time*1000) * number
				})
			}
			item.window_play_times = (thisDuration*item.play_number)/1000
		},
        previewAction () {
			this.previewData =  JSON.parse(JSON.stringify(this.programData))
			this.previewData.windows = deepCopy(this.windowList)
			this.previewVisible = true
		},
        saveAction (cbk) {
			let params =  JSON.parse(JSON.stringify(this.programData))
			params.windows = this.windowList
			this.saving = true
			saveProgram({
				uuid: this.uuid,
				content: params
			}).then(res=>{
				this.saving = false
				this.$toast('保存成功')
				cbk && cbk()
			}, err=>{
				this.saving = false
				this.$toast(err.message)
			})
		},
        cancelAction () {
			this.$bvModal
				.msgBoxConfirm(this.$t('quitProgramEditTip'), {
					title: this.$t('Tips'),
					size: 'sm',
					okVariant: 'danger',
					okTitle: this.$t('Save And Back'),
					cancelTitle: this.$t('Go Back'),
					cancelVariant: 'outline-secondary',
					hideHeaderClose: false,
					centered: true,
				})
				.then(isConfirm => {
					console.log(isConfirm)
					if (isConfirm === true) {
						this.saveAction(()=>{
							this.$router.back()
						})
					}else if (isConfirm === false){
						this.$router.back()
					}
				})
		},
		sortLayer () {
			let windows = JSON.parse(JSON.stringify(this.windowList))
			windows = windows.sort((a,b)=> b.z_index-a.z_index)
			this.windowList = windows
		},
		moveUp () {
			let zIndex = this.activeWindow.z_index
			for(let i=0; i<this.windowList.length;i++){
				let item = this.windowList[i]
				if(item.z_index == zIndex){
					item.z_index = zIndex+1
				}else if(item.z_index == zIndex+1){
					item.z_index = zIndex
				}
			}
			this.sortLayer()
		},
		moveDown () {
			let zIndex = this.activeWindow.z_index
			for(let i=0; i<this.windowList.length;i++){
				let item = this.windowList[i]
				if(item.z_index == zIndex){
					item.z_index = zIndex-1
				}else if(item.z_index == zIndex-1){
					item.z_index = zIndex
				}
			}
			this.sortLayer()
		},
		deleteLayer () {
			this.windowList.splice(this.activeTab,1)
			this.activeTab = 0
		}
	}
}
</script>

<style lang="scss">
.main-card{
	min-height:  calc(100vh - 280px);
	.card-body{
		display: flex;
		flex-direction: column;
		.tabs{
			flex: 1
		}
	}
}
</style>
