<template>
	<b-sidebar
		:visible="isActive"
		bg-variant="white"
		sidebar-class="sidebar-lg"
        width="800px"
		shadow
		backdrop
		no-header
		right
		@change="(val) => $emit('update:is-active', val)"
	>
		<template #default="{ hide }">
			<!-- Header -->
			<div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
				<h5 class="mb-0">
					{{$t('Select Materials')}}
				</h5>

				<feather-icon
					class="ml-1 cursor-pointer"
					icon="XIcon"
					size="16"
					@click="hide"
				/>

			</div>

			<!-- BODY -->
			<div class="p-2">
                <b-row>
				<!-- Search -->
                    <b-col cols="12" md="4" class="mb-1 mb-md-0">
                        <v-select
                            class="mr-1"
                            v-model="filter.type"
                            :searchable="false"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            :options="typeOptions"
                            label="name"
                            :reduce="option=>option.id"
                            :placeholder="$t('Please Select')"
                            style="width:100%"
                            disabled
                        >
                        </v-select>
                    </b-col>
                    <b-col cols="8" md="4" class="mb-1 mb-md-0">
                        <b-form-input
                            v-model="filter.query"
                            class="d-inline-block"
                            :placeholder="$t('Please Enter...')"
                            style="width:100%"
                        />
                    </b-col>
                    <b-col cols="4" md="4">
                        <b-button
                            variant="primary"
                            @click="searchAction"
                        >
                            <span class="text-nowrap">{{$t('Search')}}</span>
                        </b-button>
                    </b-col>
                </b-row>
            </div>
            <!-- data table start -->
            <b-table
                ref="deviceList"
                stacked="sm"
                primary-key="id"
                show-empty
                hover
                :empty-text="$t('No Data')"
                :fields="tableColumns"
                :items="dataList"
                v-loading="loading"
            >
                <!-- Column: Type -->
                <template #cell(type)="data">
                    {{typeOptions[data.item.type].name}}
                </template>
                <!-- Column: Actions -->
                <template #cell(actions)="data">
                    <b-button variant="outline-primary" size="sm" @click="chooseAction(data.item)">
						<span class="text-nowrap">{{$t('Select')}}</span>
					</b-button>
                </template>
            </b-table>
            <!-- Pagination -->
            <Pagination
                :currentPage="currentPage"
                :pageSize="pageSize"
                :count="dataList.length"
                v-if="dataList.length>0"
            ></Pagination>
		</template>
	</b-sidebar>
</template>

<script>
import {
	BSidebar,BRow,BCol,BTable,BPagination, BButton,BFormInput
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import Pagination from "@/components/public/Pagination.vue";
import {getMyAssets} from "@/libs/apis/serverApi"

export default {
	components: {
		BSidebar,
		BRow,BCol,BTable,BPagination, BButton,BFormInput,
        vSelect,
        Pagination
	},
	directives: {
		Ripple,
	},
	model: {
		prop: 'isActive',
		event: 'update:is-active',
	},
	props: {
		isActive: {
			type: Boolean,
			required: true,
		},
		type: {
			type: Number,
            default: 0
		}
	},
	data() {
		return {
            typeOptions: [{
                id:0,
                name: this.$t('Image')
            },{
                id:1,
                name: this.$t('Video')
            },{
                id:2,
                name: this.$t('Text')
            },{
                id:3,
                name: this.$t('Music')
            }],
            filter:{
                type: this.type,
                query: ''
            },
            loading: false,
            dataList: [],
            currentPage: 1,
            pageSize: 10,
            tableColumns: [
				{ key: 'id' },
				{ key: 'name', label: this.$t('Name') },
				{ key: 'type', label: this.$t('Type') },
				{ key: 'actions', label: this.$t('Actions') },
			],
		}
	},
	created () {
		this.getData(1)
	},
    methods: {
        getData(page) {
			this.loading = true
			page = page || 1;
			getMyAssets({
				query: this.filter.query,
				type: this.filter.type,
				offset: (page - 1) * this.pageSize,
				limit: this.pageSize,
			}).then((res) => {
				this.loading = false
				this.dataList = res.rows;
				this.currentPage = page;
			}, err=>{
				this.loading = false
				this.$toast(err.message)
			});
		},
        searchAction () {
            this.getData()
        },
        chooseAction (item) {
            this.$emit('update:is-active', false)
            this.$emit('select', item)
        }
    }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
