<template>
	<b-modal
      	:visible="isActive"
		scrollable
		centered
		@change="(val) => $emit('update:is-active', val)"
    >
		<template #modal-header="{ close }">
			<div class="d-flex w-100 justify-content-between align-items-center py-25">
				<h5 class="mb-0" v-if="type==0"> {{$t('Upload Image')}}</h5>
				<h5 class="mb-0" v-else-if="type==1"> {{$t('Upload Video')}}</h5>
				<h5 class="mb-0" v-else-if="type==2"> {{$t('Upload Text')}}</h5>
				<h5 class="mb-0" v-else-if="type==3"> {{$t('Upload Music')}}</h5>
				<h5 class="mb-0" v-else> {{$t('Upload Material')}}</h5>
				<feather-icon
					class="ml-1 cursor-pointer"
					icon="XIcon"
					size="16"
					@click="closeModal"
				/>
			</div>
		</template>
		<template #modal-footer="{ ok, cancel, hide }">
			<b-button variant="outline-secondary" :disabled="reading || uploading || fileList.length==0" @click="closeModal">{{$t('Complete')}}</b-button>
			<b-button variant="primary" :disabled="reading || uploading || startDisabled" @click="startUpload">{{$t('Start Upload')}}</b-button>
		</template>
		<div class="material-uploader" @click="$refs.refInputEl.click()">
			<slot>
				<b-button
					variant="primary"
					:disabled="reading || uploading"
				>
					<span class="d-inline">{{$t('Choose Material')}}</span>
					
				</b-button>
			</slot>
			<input
				ref="refInputEl"
				type="file"
				class="d-none"
				:multiple="true"
				:accept="accept"
				@input="inputFile"
			>
		</div>
		<div class="file-list mt-1">
			<div class="reading-tip" v-if="reading">
				<div>
					<b-spinner variant="primary" />
					<p>正在读取文件信息</p>
				</div>
			</div>
			<div v-if="fileList.length==0" class="no-data d-flex align-items-center justify-content-center text-center">
				<div>
					<!-- <p>请点击选择素材按钮，或者将需要上传的素材拖到此处</p> -->
					<p>请点击选择素材按钮</p>
					<p>支持格式：{{accept}}</p>
				</div>
			</div>
			<b-table
				ref="fileList"
				class="position-relative"
				:items="fileList"
				stacked="sm"
				:fields="tableColumns"
				primary-key="md5"
				hover
				v-else
			>
				<!-- Column: progress -->
				<template #cell(size)="data">
					{{bytesToSize(data.item.size)}}
				</template>
				<!-- Column: progress -->
				<template #cell(progress)="data">
					<div v-if="data.item.progress==0" class="text-success">等待上传</div>
					<div class="d-flex align-items-center" v-else>
						<div class="flex-1 mr-25">
							<b-progress
								v-model="data.item.progress*100"
								max="100"
							/>
						</div>
						{{data.item.progress*100}}%
					</div>
					
				</template>
			</b-table>
		</div>
  	</b-modal>
</template>

<script>
import {
  	BSidebar, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BButton,BFormFile, BTable, BProgress,BSpinner
} from 'bootstrap-vue'
import { ref, computed, reactive } from '@vue/composition-api'
import config from "@/libs/config"
const { imgType,videoType,textType,audioType } = config.fileTypes
import {useOSSUpload} from "@/hooks/useOSSUpload"
import {bytesToSize} from "@/libs/utils"
import {uploadAsset} from "@/libs/apis/serverApi"

export default {
	components: {
		BSidebar,
		BForm,
		BFormGroup,
		BFormInput,
		BFormInvalidFeedback,
		BButton,
		BFormFile,
		BTable,
		BProgress,
		BSpinner
	},
	model: {
		prop: 'isActive',
		event: 'update:is-active',
	},
	props: {
		isActive: {
			type: Boolean,
			required: true,
		},
		type: {
			type: Number,
			default: -1
		}
	},
	data() {
		return {
			tableColumns: [
				{ key: 'name', label: this.$t('Name') },
				{ key: 'size', label: this.$t('Material Size') },
				{ key: 'progress', label: this.$t('Upload Progress') }
			]
		}
	},
	methods: {
		closeModal(){
			if(this.reading || this.uploading) {
				this.$toast.error('正在上传文件请稍后')
				return
			}
			if(this.fileList.length>0){
				this.$emit('refetch-data',this.fileList)
			}
			this.$emit('update:is-active', false)
		},
		startUpload () {
			if(this.fileList.length==0){
				this.$toast.error(this.$t('Please choose material'))
				return
			}
			const max = this.fileList.length
			this.uploading = true
			
			this.fileList.forEach(async (item,index)=>{
				await this.uploadFile({
					fileData: item,
					onProgress: (progress) => {
						item.progress = progress
					}
				}).then( (res)=>{
					console.log(res)
					item.progress = 1
					let params = Object.assign({},item)
					delete params.file
					delete params.progress
					uploadAsset (params).then(res=>{
						if(index==max-1){
							this.uploading = false
							this.$toast.success('上传完成')
						}
					})
				},(err) => {
					if (err.code === 'FileAlreadyExists') {
						item.progress = 1
						let params = Object.assign({},item)
						delete params.file
						delete params.progress
						uploadAsset (params).then(res=>{
							if(index==max-1){
								this.uploading = false
								this.$toast.success('上传完成')
							}
						})
					}else{
						this.uploading = false
						this.$toast.error('上传失败')
					}
					
				})
			})
		}
	},
	setup(props, { emit }) {
		const accept = computed(()=> {
			let typeList = []
			let type = props.type
			if(type===0){
				typeList = imgType
			}else if(type == 1){
				typeList = videoType
			}else if(type == 2){
				typeList = textType
			}else if(type == 3){
				typeList = audioType
			}else{
				typeList = [...imgType, ...videoType, ...textType,...audioType]
			}
			
			typeList = typeList.map(item=> '.'+item)
            return typeList.join(',')
        })

        const {getOSSParams, getFileInfo, uploadFile} = useOSSUpload()
        getOSSParams()

        const fileList = reactive([])
		const uploading = ref(false)
		const reading = ref(false)

        const inputFile = (e) => {
            let files = e.target.files
			let max = files.length
			let index = 0
            files.forEach(async item=>{
                let fileInfo = await getFileInfo({
                    file: item,
                    onStart: ()=>{
						console.log('start')
						reading.value = true
						index++
                        // TODO
                    },
                    onEnd: ()=>{
						console.log('end')
						if(index==max){
							reading.value = false
						}
                        // TODO
                    }
                })
				fileInfo.progress = 0
				fileInfo.file = item
                fileList.push(fileInfo)
            })
            console.log(fileList)
        }

		const startDisabled = computed(()=>{
			if(fileList.length==0) return false
			let unUploadNum = fileList.filter(item=>item.progress == 0)
			return unUploadNum>0
		})

		return {
            fileList,
            accept,
			uploading,
			reading,
			startDisabled,

            getFileInfo,
            uploadFile,
            inputFile,
			bytesToSize
		}
	}
}
</script>

<style lang="scss">
.file-list{
	position: relative;
	min-height: 200px;
	.no-data{
		border:1px dashed #eee;
		min-height: 200px;
		color: #999;
	}
	.reading-tip{
		position: absolute;
		top: 0;
		left: 0;
		display: flex;
		align-items: center;
		justify-content: center;
		width: 100%;
		height: 100%;
		background: rgba(255,255,255,.6);
		color: #999;
		text-align: center;
	}
}
</style>
