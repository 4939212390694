<template>
    <b-sidebar
		:visible="isActive"
		bg-variant="white"
		sidebar-class="sidebar-lg"
        width="800px"
		shadow
		backdrop
		no-header
		right
		@change="(val) => $emit('update:is-active', val)"
	>
		<template #default="{ hide }">
			<!-- Header -->
			<div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
				<h5 class="mb-0">
					{{$t('Edit Text Content')}}
				</h5>

				<feather-icon
					class="ml-1 cursor-pointer"
					icon="XIcon"
					size="16"
					@click="hide"
				/>

			</div>

			<!-- BODY -->
            <div class="p-2">
                <div>
                    <quill-editor
                        id="quil-content"
                        height="900"
                        v-model="editorContent"
                        :options="editorOption"
                    />
                </div>
                <div class="d-flex mt-2">
                    <b-button
                        variant="primary"
                        class="mr-2"
                        type="button"
						@click="saveAction"
                    >
                        {{$t('Save')}}
                    </b-button>
                    <b-button
                        type="button"
                        variant="outline-secondary"
                        @click="$emit('update:is-active', false)"
                    >
                        {{$t('Cancel')}}
                        
                    </b-button>
                </div>
            </div>
        </template>
    </b-sidebar>
</template>

<script>
import {mapState} from 'vuex'
import {
	BCard, BForm, BFormGroup, BFormInput, BSidebar, BButton,BRow,BCol
} from 'bootstrap-vue'

import 'quill/dist/quill.core.css' // import styles
import 'quill/dist/quill.snow.css' // for snow theme
import 'quill/dist/quill.bubble.css' // for bubble theme
import { quillEditor } from 'vue-quill-editor'

export default {
	components: {
		BCard,
		BForm,
		BFormGroup,
		BFormInput,
		BSidebar,
		BButton,
		BRow,BCol,

		quillEditor
	},
	model: {
		prop: 'isActive',
		event: 'update:is-active',
	},
	props: {
		isActive: {
			type: Boolean,
			required: true,
		},
		content: {
			type : String
		}
	},
	data() {
		return {
			editorContent: this.content,
			editorOption: {
				modules:{
					toolbar:[
						['bold', 'italic', 'underline', 'strike'],    //加粗，斜体，下划线，删除线
						[{ 'align': [] }],    //对齐方式
						['clean']    //清除字体样式
					]
				},
				placeholder: this.$t('Please Enter...'),
				height:800
			},
		}
	},
	created () {
	},
	methods: {
		saveAction () {
			this.$emit('update:content', this.editorContent)
			this.$emit('update:is-active', false)
		}
	},
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/quill.scss';
</style>

<style lang="scss" scoped>
.quill-editor {
	height: 500px;
	margin-bottom: 60px;
}
</style>