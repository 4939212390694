<style lang="scss">
.text-node-wrap{
    .text-node{
        p{
            line-height: inherit;
        }
    }
    &.single{
        .text-node{
            p{
                white-space: nowrap;
            }
        }
    }
    &.multi{
        .text-node{
            p{
                word-break: break-all;
            }
        }
    }
}
</style>
<style lang="scss" scoped>
.text-node-wrap{
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    &.single{
        align-items: center;
        .text-node{
            display: flex;
        }
    }
    &.multi{
        justify-content: center;
        .text-node{
            position: absolute;
            width: 100%;
        }
    }

    .text-node{
        position: relative;
        top: -10px;
    }
}

</style>
<template>
    <div v-if="textNode"
        class="text-node-wrap"
        :class="{
            single: textNode.multi_line==0, 
            multi: textNode.multi_line==1
        }"
        :style="bgStyle"
    >
         <div ref="textNode" class="text-node" :style="{...textStyle,...postionStyle}" v-html="textNode.content"></div>
    </div>
   
</template>
<script>
import {mapState} from 'vuex'
let scrollTimer = null
export default {
	name: 'textWindow',
    props: {
        textNode:Object,
        scale: Number,
        realW: Number,
        realH: Number,
        startTimeMs: {
            type: Number,
            default:0
        },
        endTimeMs:  {
            type: Number,
            default:0
        },
    },
    data () {
        return {
            top: this.realH,
            left: this.realW
        }
    },
    computed: {
		...mapState({
            programPlaying: state => state.app.programPlaying,
            programPlayMs: state => state.app.programPlayMs
        }),
        textStyle () {
            let textNode = this.textNode
            return {
                lineHeight: (textNode.line_height*this.scale)+'px',
                color: textNode.text_color,
                fontSize: (textNode.font_size*this.scale)+'px',
                letterSpacing:textNode.letter_spacing+'px'
            }
        },
        bgStyle () {
            return {
                backgroundColor: this.textNode.bg_color,
            }
        },
        postionStyle () {
            if(this.textNode.multi_line==0){
                return {left: this.left + 'px', top:0}
            }
            return {top: this.top + 'px', left: 0}
        },
        speed () {
            return this.textNode.speed/this.scale
        }
    },
    watch: {
        programPlaying (value) {
            // if(value){
            //     this.start()
            // }else{
            //     this.pause()
            // }
        },

        programPlayMs (value) {
            // console.log('programPlayMs',value)
            if(this.textNode.multi_line == 1){
                this.top = this.realH - (value-this.startTimeMs)/this.speed
            }else{
                this.left = this.realW - (value-this.startTimeMs)/this.speed
            }
        },
    },
    mounted () {
        if(this.programPlaying){
            // this.start()
        }
        
    },
    beforeDestroy() {
        // console.log('beforeDestroy')
        this.stop()
    },
    methods: {
        start () {
            clearInterval(scrollTimer)
            if(this.textNode.multi_line == 1){
                this.veritcalScroll()
            }else{
                this.horizonalScroll()
            }
        },
        pause () {
            clearInterval(scrollTimer)
        },
        stop () {
            clearInterval(scrollTimer)
            this.left = this.realW
            this.top = this.realH
        },
        veritcalScroll () {
            const textNode = this.$refs.textNode
            const height = textNode.offsetHeight
            const maxH = this.realH+height
            // console.log('height,maxH',height,maxH)
            scrollTimer = setInterval(()=>{
                this.top -= 1
                if(this.top <= -maxH){
                    clearInterval(scrollTimer)
                }
            }, this.speed)
        },

        horizonalScroll () {
            const textNode = this.$refs.textNode
            const width = textNode.offsetWidth
            const maxW = this.realW+width
            scrollTimer = setInterval(()=>{
                this.left -= 1
                if(this.left <= -maxW){
                    clearInterval(scrollTimer)
                }
            }, this.speed)
        }
	},
}
</script>