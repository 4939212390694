<style lang="scss" scoped>
.media-window{
	position: relative;
    width: 100%;
    height: 100%;
}
</style>
<template>
	<div class="media-window" v-if="mediaShow">
		<!-- <span style="position:absolute;color:#fff;word-break:break-all;">{{playIndex}}</span> -->
		<!-- && ((!programPlaying && activeIndex==mediaIndex) || (programPlaying && playIndex == mediaIndex))" -->
        <template v-for="(mediaItem,mediaIndex) in activeData.file_list">
            <imageWindow 
                :mediaItem="mediaItem"
                v-if="playIndex == mediaIndex"
                :key="mediaIndex"
				:scale="scale"
				:realW="winData.width"
				:realH="winData.height">
            </imageWindow>
        </template>
	</div>
</template>
<script>
import {mapState} from 'vuex'
import imageWindow from './mediaImage.vue'
import {deepCopy} from "@/libs/utils"
export default {
	components: {
		imageWindow
	},
	name: 'mediaWindow',
	props: {
		winData: Object,
		scale: Number,
        activeIndex: Number,
		musicData: Object
	},
	data() {
		return {
			activeData: {},
            mediaShow: true,
			// playIndex: -1
		}
	},
	computed: {
		...mapState({
            programPlaying: state => state.app.programPlaying,
            programPlayMs: state => state.app.programPlayMs
        }),
		playIndex () {
			if(!this.activeData || !this.activeData.file_list) return -1
			let temp = -1
			let fileList = this.activeData.file_list
			for(let i=0;i<fileList.length;i++){
				if(this.programPlayMs>=fileList[i].startTimeMs && this.programPlayMs<=fileList[i].endTimeMs){
					temp = i
					break
				}
			}
			return temp
		}
	},
	watch: {
		programPlayMs (value) {
			// console.log('watch programPlayMs',value)
		},
		playIndex (value) {
			// console.log('watch playIndex',value)
			this.mediaShow = false
			setTimeout(() => {
				this.mediaShow = true
			}, 50);
		}
	},
	methods: {
		setData () {
			this.activeData = deepCopy(this.winData)
			let fileList = this.activeData.file_list
			fileList.forEach((item,index)=>{
				item.totalMs = item.into+item.stay

				if(index==0){
					item.startTimeMs = this.activeData.start_time*1000
				}else{
					let time = 0
					for(let i=0; i<index;i++){
						time += fileList[i].totalMs
					}
					item.startTimeMs = time + this.activeData.start_time*1000
				}
				
				item.endTimeMs = item.startTimeMs+item.totalMs
			})
		},
		render () {
			this.setData()
			this.mediaShow = false
			setTimeout(() => {
				this.mediaShow = true
			}, 100);
        }
	},
	created() {
		this.render()
	},
	mounted() {}
}
</script>