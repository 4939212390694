<style scoped lang="scss">
.proeditor-container{
    .screen-wrapper{
        padding-bottom: 100px;
    }
    .proeditor-layer{
        border-right: none;
    }
    .proeditor-title{
        
        border:none
    }
	.screen{
		// border: none;
		// background: #000;
		overflow: hidden;
		.screen-area{
			border: none;
		}
	}
}


</style>
<template>
  	<div class="proeditor-container">
		<div class="d-flex flex-column flex-1">
			<div class="proeditor-toolbar d-flex align-items-center px-1">
				<div class="proeditor-layer flex-1">
					<div class="proeditor-title">{{$t('Program Name')}}：{{activeData.name}} <small>（尺寸：1920X1080）</small></div>
				</div>
				<feather-icon class="cursor-pointer" size="20" icon="XIcon" @click="closeAction" />
			</div>
			<div class="proeditor-main flex-1" v-if="program">
				<div class="d-flex flex-1 screen-main">
					<div class="screen-wrapper" :style="{height: mainH+'px',width: mainW+'px'}">
						<div class="screen" :style="{
							width: (program.screen_w*realScale) +'px',
							minWidth: (program.screen_w*realScale)+'px',
							height: (program.screen_h*realScale)+'px'
						}">
							<div class="screen-area"
								:class="{active: activeLayer && activeLayer.id==item.id}"
								:style="{
									left: item.start_x*realScale+'px',
									top: item.start_y*realScale+'px',
									width: item.width*realScale+'px',
									height: item.height*realScale+'px',
									zIndex: item.z_index+1
								}"
								:data-type="item.type"
								:id="item.id"
								v-for="(item,index) in layerList"
								:key="index"
							>
								<!-- 视频 -->
								<videoWindow v-if="item.type==0" :ref="'node'+item.id" :winData="item" :scale="realScale" :musicData="musicData"></videoWindow>
								<!-- 图片-->
								<imageWindow v-if="item.type==1" :ref="'node'+item.id" :winData="item" :scale="realScale"></imageWindow>
								<!-- 文本 -->
								<textWindow v-if="item.type==2" :ref="'node'+item.id" :winData="item" :scale="realScale"></textWindow>
								<!-- 音乐 -->
								<musicWindow v-if="item.type==3" :ref="'node'+item.id" :winData="item" :scale="realScale"></musicWindow>
							</div>
						</div>
						<control ref="control"
							:layerList="layerList"
							:editType="0"
							v-model="activeLayer"></control>
					</div>
					
				</div>

			</div>
		</div>
	</div>
</template>

<script>
import {mapState} from 'vuex'
import { BCard, BCardText, BRow, BCol,BButton, BFormInput, BBadge, BTabs, BTab,BDropdown,BDropdownItem } from 'bootstrap-vue'
import textWindow from './textWindow.vue'
import videoWindow from './videoWindow.vue'
import imageWindow from './imageWindow.vue'
import musicWindow from './musicWindow'
import control from './playerControl'
import {deepCopy} from "@/libs/utils"

let resizeTimer = null
export default {
  	name: 'editor',
	components: {
		BCard, BCardText, BRow, BCol,BButton, BFormInput, BBadge, BTabs, BTab,BDropdown,BDropdownItem,
		textWindow,
		videoWindow,
		imageWindow,
		musicWindow,
		control,
	},
  	props: {
    	activeData: Object,
		groupData: Object,
		type: String,   //添加节目单的类型 all 所有，media 只能添加媒体类（媒体、音乐），text 只能添加文字
        isAD:{
            type: Boolean,
            default:false
        }
  	},
	data(){
		return {
			program:null,
			scale: 100,
			activeLayer:null,
			activeLayerIndex: 0,
			activeTextIndex: 0,
			layerList:[],
			activeMediaIndex:-1,
			totalMs: 0,
			timeArr:[],
			winSize: {
				height:500,
				width:1000
			}
		}
	},
	computed: {
		...mapState({
			programPlaying: state => state.app.programPlaying
		}),
		mainH () {
			return this.winSize.height-60
		},
		mainW () {
			return this.winSize.width
		},
		realScale () {
			return this.scale/100
		},
		musicData() {
			return this.program.windows.find(item=> item.type==6)
		}
	},
	created () {
		// console.log(this.activeData)
		if(!this.activeData) return
		this.program = this.activeData
        this.getWinSize()
		this.init()
	},
    mounted() {
        window.onresize = () => {
            clearTimeout(resizeTimer);
            resizeTimer = setTimeout(() => {
                this.getWinSize()
            }, 500)
        }
    },
	destroyed () {
		console.log('editor destoyed')
		this.$store.commit('app/setProgramPlaying',false)
		this.$store.commit('app/setProgramPlayMs',0)
	},
	methods: {
        getWinSize() {
            let winH = document.documentElement.clientHeight || document.body.clientHeight
            let winW = document.documentElement.clientWidth || document.body.clientWidth
            this.winSize = {
				height: winH,
				width: winW
			}
        },
		getLayerList () {
			let layerList = []
			this.program.windows.forEach(item=>{
				let temp = deepCopy(item)
				if(item.type!=2){
					temp.file_list = []
					let fileList = item.file_list
					for(let j=0; j<item.play_number; j++){
						fileList.forEach(fItem => {
							for(let i=0;i<fItem.play_number;i++){
								temp.file_list.push(fItem)
							}
						})
					}
				}else{
					temp.txt_list = []
					let fileList = item.txt_list
					for(let j=0; j<item.play_number; j++){
						fileList.forEach(fItem => {
							for(let i=0;i<fItem.play_number;i++){
								temp.txt_list.push(fItem)
							}
						})
					}
				}
				
				layerList.push(temp)
			})
			return layerList
		},
		init () {
			let scale = Math.min((this.mainW-300)/this.program.screen_w,(this.mainH-280)/this.program.screen_h)
			this.scale = ((+scale)*100)-5
			this.scale = Math.min(100,this.scale)
			this.scale = +this.scale.toFixed(2)
			this.layerList = this.getLayerList()
			if(this.layerList.length>0){
				this.sortLayer(true)
			}
			this.layerList.forEach(item=>{
				if(item.type=='0'){
					this.activeMediaIndex = item.file_list.length>0? 0 : -1
				}
			})
		},
		sortLayer () {
			this.layerList.sort((a,b)=>b.z_index-a.z_index)
		},
		closeAction () {
			this.$emit('close')
		},
	}
}
</script>
