<template>
    <!-- 窗口属性 -->
    <b-card class="shadow-none bg-transparent border">
        <div class="d-flex align-items-center mb-1">
            <feather-icon icon="ImageIcon" v-if="activeData.type==0" size="20" class="mr-25"></feather-icon>
            <feather-icon icon="VideoIcon" v-if="activeData.type==1" size="20" class="mr-25"></feather-icon>
            <feather-icon icon="FileTextIcon" v-if="activeData.type==2" size="20" class="mr-25"></feather-icon>
            <feather-icon icon="MusicIcon" v-if="activeData.type==3" size="20" class="mr-25"></feather-icon>
            <h5 class="ml-25 mb-0 text-nowrap">{{$t('Window Attributes')}}</h5>
        </div>
        <b-row>
            <!-- 窗口名称 -->
            <b-col :cols="12" :md="4">
                <b-form-group :label="$t('Window Name')">
                    <b-form-input
                        v-model="activeData.name"
                        trim
                        :placeholder="$t('Please Enter...')"
                    />
                </b-form-group>
            </b-col>
            <!-- 尺寸 -->
            <b-col :cols="12" :md="4">
                <b-form-group :label="$t('Size')">
                    <div class="d-flex align-items-center">
                        <p>{{$t('Width')}}：</p>
                        <div class="flex-1">
                            <b-form-input
                                v-model.nubmer="activeData.width"
                                trim
                                type="number"
                                :placeholder="$t('Please Enter...')"
                            />
                        </div>
                        <p class="ml-1">{{$t('Height')}}：</p>
                        <div class="flex-1">
                            <b-form-input
                                v-model.nubmer="activeData.height"
                                trim
                                type="number"
                                :placeholder="$t('Please Enter...')"
                            />
                        </div>
                    </div>
                </b-form-group>
            </b-col>
            <!-- 位置 -->
            <b-col :cols="12" :md="4">
                <b-form-group :label="$t('Position')">
                    <div class="d-flex align-items-center">
                        <p>X：</p>
                        <div class="flex-1">
                            <b-form-input
                                v-model="activeData.start_x"
                                trim
                                type="number"
                                :placeholder="$t('Please Enter...')"
                            />
                        </div>
                        <p class="ml-1">Y：</p>
                        <div class="flex-1">
                            <b-form-input
                                v-model="activeData.start_y"
                                trim
                                type="number"
                                :placeholder="$t('Please Enter...')"
                            />
                        </div>
                    </div>
                </b-form-group>
            </b-col>
            <!-- 开始时间 -->
            <b-col :cols="12" :md="4">
                <b-form-group :label="$t('Start Time')">
                    <b-input-group append="s" class="input-group-merge">
                        <b-form-input
                            v-model="activeData.start_time"
                            trim
                            type="number"
                            :placeholder="$t('Please Enter...')"
                        />
                    </b-input-group>
                </b-form-group>
            </b-col>
            <!-- 边框类型 -->
            <b-col :cols="12" :md="4">
                <b-form-group :label="$t('Border Type')">
                    <v-select
                        v-model="activeData.window_border_type"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        label="name"
                        :searchable="false"
                        :clearable="false"
                        :reduce="option=>option.id"
                        :options="borderTypeList"
                        :placeholder="$t('Please Select')"
                    />
                </b-form-group>
            </b-col>
            <!-- 边框颜色 -->
            <b-col :cols="12" :md="4">
                <b-form-group :label="$t('Border Color')">
                    <color-picker v-model="activeData.window_border_color"></color-picker>
                </b-form-group>
            </b-col>
            <!-- 播放次数 -->
            <b-col :cols="12" :md="4">
                <b-form-group :label="$t('Play Number')">
                    <b-form-input
                        v-model="activeData.play_number"
                        trim
                        :placeholder="$t('Please Enter...')"
                        @input="$emit('update')"
                    />
                </b-form-group>
            </b-col>
            <!-- 播放次数 -->
            <b-col :cols="12" :md="4">
                <b-form-group :label="$t('Play Duration')">
                    <p class="pt-50">{{activeData.window_play_times}} s</p>
                </b-form-group>
            </b-col>
        </b-row>
    </b-card>
</template>
<script>
import { BCard, BCardText, BRow, BCol,BButton, BFormInput, BBadge, BTabs, BTab,BFormGroup, BInputGroup, BFormSpinbutton } from 'bootstrap-vue'
import draggable from "vuedraggable";
import vSelect from 'vue-select'
import { VueColorpicker } from 'vue-colorpicker-pop'
export default {
	components: {
		BCard,
		BCardText,
		BRow, BCol,
		BButton,
		BFormInput,
		BBadge, BTabs, BTab,
        BFormGroup,BInputGroup, BFormSpinbutton,
        draggable,
        vSelect,
        'color-picker': VueColorpicker
	},
    props: {
        winData: {
            type: Object,
            default: {}
        }
    },
	data () {
		return {
            activeData: {},

            borderTypeList: [{
                id: 0,
                name: '无'
            },{
                id: 1,
                name: '单线'
            },{
                id: 2,
                name: '双线'
            },{
                id: 3,
                name: '三线'
            }],
		}
	},
    created () {
        this.activeData = this.winData
    },
    methods: {
        
    }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
