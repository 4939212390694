<style scoped lang="scss">
.player-control{
    position: absolute;
    bottom: 20px;
    left: 20px;
    right: 20px;
    padding: 10px 20px;
    height: 60px;
    background: #f5f5f5;
    border-radius: 5px;
    user-select: none;
    .control-progress{
        position: relative;
        width: 100%;
        height: 3px;
        background: #999;
        .slider{
            position: absolute;
            top: -3px;
            display: block;
            width: 10px;
            height: 10px;
            box-shadow: 0 0 10px rgba(0,0,0,0.1);
            background: #7367f0;
            border-radius: 50%;
            cursor: pointer;
        }
        .current-time{
            position: absolute;
            top: 10px;
            width: 100px;
            user-select: none;
            text-align: center;
        }
    }
}
</style>
<style lang="scss">
.speed-menu {
    .v-list-item{
        padding: 0;
        text-align: center;
        
    }
    .v-list-item__title{
        line-height:40px;
        cursor: pointer;
        &.active{
            background: #7367f0;
            color: #fff;
        }
    }
}
</style>
<template>
    <div class="player-control d-flex align-items-center">
        <div class="mr-1" style="cursor:pointer">
            <feather-icon size="20" icon="PauseCircleIcon" v-if="isPlaying" @click="pauseAction" />
            <feather-icon size="20" icon="PlayCircleIcon" v-else @click="playAction" />
        </div>
        <!-- <p></p> -->
        <b-dropdown
            :text="`${$t('Speed')}:${currentSpeed}`"
            variant="flat-primary"
        >
            <b-dropdown-item
                v-for="(item, index) in speedList" :key="index"
                :class="{active: currentSpeed==item}" @click="changeSpeed(item)"
            >
                {{item}}
            </b-dropdown-item>
        </b-dropdown>
        
        <div class="ml-1">00:00:00</div>
        <div class="flex-1 mx-1">
            <div class="control-progress" ref="timeline">
                <span v-dragX class="slider" :style="{left:axiosX+'px'}"></span>
                <span class="current-time" :style="{left:axiosX-45+'px'}" :class="{left: timeWidth-axiosX<100}">{{playTime}}</span>
            </div>
        </div>
        <div class="time-item">{{formatTime(totalTime)}}</div>
    </div>
</template>
<script>
import { BDropdown,BDropdownItem } from 'bootstrap-vue'
import {deepCopy, secondsToTimeObj} from "@/libs/utils"
let playTimer = null
export default {
    name: 'playerControl',
	components: { BDropdown,BDropdownItem },
    directives: {
        dragX: {
            // 指令的定义
            bind: (el,binding,vnode) => {
				let that = vnode.context
                let oDiv = el;
                oDiv.onmousedown = (e) => {
                    let disX = e.clientX - oDiv.offsetLeft;
                    document.onmousemove = (e)=>{
                        let left = e.clientX - disX;
						left = Math.max(left,0)
						left = Math.min(left, that.realWidth-10)
                        that.playMs = Math.floor((left/that.realWidth) * that.totalMs)
                    };
                    document.onmouseup = (e) => {
                        document.onmousemove = null;
                        document.onmouseup = null;
                    };
                };
            }
        }
    },
    model: {
        prop: 'activeLayer',
        event: 'change'
    },
    props: {
        layerList: {
            type:Array,
            default () {
                return []
            }
        },
        activeLayer: {
            type: Object,
            default () {
                return null
            }
        }
    },
    computed: {
        realWidth () {
            return this.timeWidth * this.zoom /100
        },
        totalMs () {
            return this.totalTime*1000
        },
        axiosX () {
            if(this.totalTime==0) return 0
            let temp = 0
            temp = (this.playMs/(this.totalMs)) * this.realWidth
            return temp
        },
        playTime () {
            // console.log(this.playMs)
            let timeObj = secondsToTimeObj(Math.ceil(this.playMs/1000))
            let ms = this.playMs%1000
            return `${timeObj.HH}:${timeObj.mm}:${timeObj.ss}`//:${ms}
        },
    },
    data () {
        return {
            width: 100,
            totalTime: 0,
            list:[],
            isMounted: false,
            zoom: 100,
            timeWidth: 100,
            isPlaying: false,
            playMs: 0,
            speedList: ['1.0','1.5','2.0','4.0','8.0'],
            currentSpeed: '1.0'
        }
    },
    watch: {
        layerList: {
            handler(value) {
                if(this.isMounted){
                    this.render()
                }
            },
            deep: true
        },
        activeLayer () {
            this.timeWidth = this.getWidth()
        },
        playMs (value) {
            this.$store.commit('app/setProgramPlayMs',value)
        },
        isPlaying (value) {
            this.$store.commit('app/setProgramPlaying',value)
        }
    },
    created () {
        // console.log(this.layerList)
    },
    mounted () {
        this.render()
        this.isMounted = true
        this.timeWidth = this.getWidth()
        this.playAction()
    },
    methods: {
        changeSpeed (item) {
            this.currentSpeed = item
            this.$store.commit('app/setProgramPlaySpeed',this.currentSpeed)
            if(this.isPlaying){
                this.playStart()
            }
        },
        playAction () {
            this.isPlaying = true
            this.playStart()
        },
        playStart (fromStart) {
            let speed = + this.currentSpeed
            clearInterval(playTimer)
            if(fromStart){
                this.playMs = 0
            }
            // 
            if(this.totalMs == 0) return
            
			playTimer = setInterval(() => {
				this.playMs+=10*speed
				if(this.playMs>=this.totalMs){
					clearInterval(playTimer)
					this.playStart(true)
				}
			}, 10);
        },
        pauseAction () {
            this.isPlaying = false
            clearInterval(playTimer)
            // this.playMs = 0
        },
        stopAction () {
            this.isPlaying = false
            clearInterval(playTimer)
            this.playMs = 0
        },
        formatTime (seconds) {
            let timeObj = secondsToTimeObj(seconds)
            return `${timeObj.HH}:${timeObj.mm}:${timeObj.ss}`
        },
        getWidth () {
            let w = window.getComputedStyle(this.$refs.timeline).width
            return +(w.slice(0, -2))
        },
        computeTotalTime () {
            let total = 0
            this.layerList.forEach(item=>{
                let endTime = item.window_play_times + item.start_time
                item.end_time = endTime
                total = Math.max(endTime,total)
            })
            this.totalTime = total
        },
        render () {
            // console.log('render timeline')
            this.computeTotalTime()
            
            if(this.totalTime == 0) return
            let layerList = deepCopy(this.layerList)
            this.list = layerList.map(item=>{
                // console.log(item)
                let itemTotal = item.end_time-item.start_time
                item.elStart = (item.start_time/this.totalTime)*100
                item.percent = (itemTotal/this.totalTime)*100
                if(item.type==0 || item.type==1){
                    item.file_list.forEach(fItem=>{
                        if(fItem.type==1){
                            fItem.total = fItem.into/1000 + fItem.stay/1000
                        }else{
                            fItem.total = fItem.into/1000 + fItem.duration
                        }
                        fItem.percent = (fItem.total/itemTotal)*100
                    })
                } else if(item.type==2){
                    item.txt_list.forEach(fItem=>{
                        fItem.total = fItem.stay/1000
                        fItem.percent = (fItem.total/itemTotal)*100
                    })
                }
                return item
            })
            // console.log(this.list)
        },
    }
}
</script>