<style lang="scss" scoped>
.text-window {
    position: relative;
    width: 100%;
    height: 100%;
    border: 0px solid #000;
    overflow: hidden;
}
</style>
<template>
    <div class="text-window" :style="windowStyle" v-if="activeData">
        <template v-for="(item,index) in activeData.txt_list">
            <textNode 
                :textNode="item"
                v-if="playIndex == index"
                :key="'textNode'+index"
				:scale="scale"
				:realW="activeData.width*scale"
				:realH="activeData.height*scale"
                :startTimeMs="item.startTimeMs"
                :endTimeMs="item.endTimeMs">
            </textNode>
        </template>
    </div>
</template>
<script>
import {mapState} from 'vuex'
import textNode from './textNode.vue'
import {deepCopy} from "@/libs/utils"
export default {
    name: 'textWindow',
    components: {
        textNode
    },
    props: {
        winData: Object,
        activeIndex: Number,
        scale: Number
    },
    data() {
        return {
            activeData: null,
            textList: [],
            isAnim: false,
            textData: {},
            visible: true,
            timer: null,
            activeText: null,
            previewList: [],
        }
    },
    computed: {
		...mapState({
            programPlaying: state => state.app.programPlaying,
            programPlayMs: state => state.app.programPlayMs
        }),
        playIndex() {
            if (!this.activeData || !this.activeData.txt_list) return -1
            let temp = -1
            let textList = this.activeData.txt_list
            for (let i = 0; i < textList.length; i++) {
                if (this.programPlayMs >= textList[i].startTimeMs && this.programPlayMs <= textList[i].endTimeMs) {
                    temp = i
                    break
                }
            }
            // console.log(temp) 
            return temp
        },
        windowStyle () {
            return {
                borderWidth: this.winData.window_border_type+'px',
                borderColor: this.winData.window_border_color
            }
        }
    },

    watch: {
        programPlayMs(value) {
            // console.log('watch programPlayMs',value)
        },
        playIndex(value) {
            // console.log('watch playIndex',value)

        },
        programPlaying (value) {
            // console.log('watch programPlaying',value)
        },
    },
    created() {
        // console.log('textWindow Created',this.activeData)
        this.render()
    },
    methods: {
        setData () {
			let winData = deepCopy(this.winData)
			let textList = winData.txt_list
			textList.forEach((item,index)=>{
				item.totalMs = item.play_times*1000
				if(index==0){
					item.startTimeMs = winData.start_time*1000
				}else{
					let time = 0
					for(let i=0; i<index;i++){
						time += textList[i].totalMs
					}
					item.startTimeMs = time + winData.start_time*1000
				}
				
				item.endTimeMs = item.startTimeMs+item.totalMs
			})
            this.activeData = winData
		},
        render() {
            this.setData()
        },
    },
}
</script>
