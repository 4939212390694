<template>
    <div>
        <!-- 基础属性 -->
        <window-attr @update="$emit('update:list')" :winData="winData" />
        <!-- 视频列表及属性 -->
        <b-card  class="shadow-none bg-transparent border">
            <div class="d-flex flex-wrap align-items-center mb-1">
                <feather-icon
                    icon="ListIcon"
                    class="cursor-pointer"
                    size="16"
                    @click="deleteAction(data.item)"
                />
                <h5 class="ml-25 mb-0 text-nowrap">{{$t('Materials And Attributes')}}</h5>
                <b-button class="ml-1" variant="primary" size="sm" @click="selectMaterial">
                    {{$t('Select Image')}}
                </b-button>
                <b-button class="ml-1" variant="outline-primary" size="sm" @click="uploadMaterial">
                    {{$t('Upload Image')}}
                </b-button>
            </div>
            <template v-if="mediaList.length>0">
                <draggable
                    class="media-list"
                    v-model="mediaList"
                    v-bind="dragOptions"
                    @start="isDragging = true"
                    @end="isDragging = false"
                >
                    <div class="media-item" 
                        :class="{active:mediaIndex==activeIndex}"
                        v-for="(mediaItem,mediaIndex) in mediaList"
                        :key="mediaIndex"
                        @click="chooseMedia(mediaIndex)">
                        <img :src="mediaItem.file_url" :alt="mediaItem.name">
                        <a class="delete" href="javascript:;" @click.stop="deleteMedia(mediaIndex)">×</a>
                    </div>
                </draggable>
                <div class="splitter my-1"></div>
            </template>
            <div class="grey--text py-4 text-center" v-else>{{$t('No Material')}}</div>
            <b-row v-if="activeMedia">
                <!-- 入场时间 -->
                <b-col :cols="12" :md="4">
                    <b-form-group :label="$t('Entry Time')">
                        <b-input-group append="ms" class="input-group-merge">
                            <b-form-input
                                v-model="activeMedia.into"
                                trim
                                type="number"
                                :placeholder="$t('Please Enter...')"
                                @input="$emit('update:list')"
                            />
                        </b-input-group>
                    </b-form-group>
                </b-col>
                <!-- 入场动画 -->
                <b-col :cols="12" :md="4">
                    <b-form-group :label="$t('Entry Animation')">
                        <v-select
                            class="mr-1"
                            v-model="activeMedia.sfx"
                            :searchable="false"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            :options="animList"
                            label="name"
                            :reduce="option=>option.id"
                            :placeholder="$t('Please Select')"
                            style="width:100%"
                        >
                        </v-select>
                    </b-form-group>
                </b-col>
                
                <!-- 停留时间 -->
                <b-col :cols="12" :md="4">
                    <b-form-group :label="$t('Stay Time')">
                        <b-input-group append="ms" class="input-group-merge">
                            <b-form-input
                                v-model="activeMedia.stay"
                                trim
                                type="number"
                                :placeholder="$t('Please Enter...')"
                                @input="$emit('update:list')"
                            />
                        </b-input-group>
                    </b-form-group>
                </b-col>
                <!-- 播放次数 -->
                <b-col :cols="12" :md="4">
                    <b-form-group :label="$t('Play Number')">
                        <b-form-input
                            v-model="activeMedia.play_number"
                            trim
                            :placeholder="$t('Please Enter...')"
                            @input="$emit('update:list')"
                        />
                    </b-form-group>
                </b-col>
                <!-- 透明度 -->
                <b-col :cols="12" :md="4">
                    <b-form-group :label="$t('Transparent')">
                        <b-form-spinbutton
                            v-model="activeMedia.transparent"
                            inline
                            min="1"
                            max="100"
                            step="1"
                        />
                    </b-form-group>
                </b-col>
            </b-row>
        </b-card>
        <MaterialSelect :type="0" v-model="selectSidebarVisible" @select="onSelectMaterial" />
        <!-- upload -->
		<upload
			v-if="uploadModalVisible"
			:is-active.sync="uploadModalVisible"
            :type="0"
			@refetch-data="addFileList"
		></upload>
    </div>
</template>
<script>
import { BCard, BCardText, BRow, BCol,BButton, BFormInput, BBadge, BTabs, BTab,BFormGroup, BInputGroup, BFormSpinbutton } from 'bootstrap-vue'
import draggable from "vuedraggable";
import vSelect from 'vue-select'
import { VueColorpicker } from 'vue-colorpicker-pop'
import WindowAttr from "./WindowAttr.vue"
import MaterialSelect from "./MaterialSelect.vue"
import Upload from "@/components/material/Upload.vue"
import {fullMaterialUrl} from "@/libs/utils"
import config from "@/libs/config"

export default {
	components: {
		BCard,
		BCardText,
		BRow, BCol,
		BButton,
		BFormInput,
		BBadge, BTabs, BTab,
        BFormGroup,BInputGroup, BFormSpinbutton,
        draggable,
        vSelect,
        'color-picker': VueColorpicker,
        WindowAttr,
        MaterialSelect,
        Upload
	},
    props: {
        winData: {
            type: Object,
            default: {}
        }
    },
	data () {
		return {
			mediaList: [],
            activeIndex: -1,
            isDragging: false,
            activeData: {},
            dragOptions: {
                animation: 300,
                group: "description",
                disabled: false,
                ghostClass: "ghost"
            },
            selectSidebarVisible: false,
            uploadModalVisible: false
		}
	},
    computed: {
        activeMedia () {
            if(this.activeIndex == -1) return null
            return this.mediaList[this.activeIndex]
        },
        animList () {
            return config.animList.map(item=>{
                item.name = this.$t(item.name)
                return item
            })
        }
    },
    created () {
        this.mediaList = this.winData.file_list
        if(this.mediaList.length>0){
            this.activeIndex = 0
        }
    },
    methods: {
        uploadMaterial () {
            this.uploadModalVisible = true
        },
        addFileList (fileList) {
            fileList.forEach(item=>{
                this.addMedia(item)
            })
        },
        selectMaterial () {
            this.selectSidebarVisible = true
        },
        onSelectMaterial (item) {
            console.log(item)
            this.addMedia(item)
        },
        addMedia (item) {
            this.mediaList.push({
                file_path: '',
                file_md5: item.md5,
                file_url: fullMaterialUrl(item.md5,item.suffix),
                transparent: 100,
                play_number: 1,
                // 网络视频有以下属性
                stay: 3000,
                into:1000,
                sfx: 0
            })
            this.activeIndex = this.mediaList.length-1
            this.$emit('update:list')
        },
        deleteMedia (index) {
            this.mediaList.splice(index,1)
            if(this.mediaList.length==0){
                this.activeIndex = -1
            }
            this.$emit('update:list')
        },
        chooseMedia (index) {
            this.activeIndex = index
        }
    }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
