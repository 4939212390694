<template>
    <div>
        <!-- 基础属性 -->
        <window-attr @update="$emit('update:list')" :winData="winData" />
        <!-- 列表及属性 -->
        <b-card  class="shadow-none bg-transparent border">
            <div class="d-flex flex-wrap align-items-center mb-1">
                <feather-icon
                    icon="ListIcon"
                    class="cursor-pointer"
                    size="16"
                    @click="deleteAction(data.item)"
                />
                <h5 class="ml-25 mb-0 text-nowrap">{{$t('Text List And Attributes')}}</h5>
                <b-button class="ml-1" variant="primary" size="sm" @click="selectText">
                    {{$t('Select Text')}}
                </b-button>
                <b-button class="ml-1" variant="outline-primary" size="sm" @click="addText()">
                    {{$t('Add Text')}}
                </b-button>
                <b-button class="ml-1" variant="outline-primary" size="sm" @click="uploadMaterial">
                    {{$t('Upload Text')}}
                </b-button>
            </div>
            <!-- 文本列表  -->
            <b-tabs class="text-tabs" align="left" v-model="activeTextIndex">
                <template v-slot:empty>
                    <div class="grey--text py-4 text-center">{{$t('No Text')}}</div>
                </template>
                <b-tab v-for="(item, index) in textList" :key="index">
                    <template v-slot:title>
                        <div class="text-item">
                            文本{{index+1}}
                            <feather-icon
					            :id="`delete-icon-${index}`"
                                icon="XIcon"
                                class="cursor-pointer"
                                size="16"
                                @click="deleteText(index)"
                            />
                            <b-tooltip
                                :title="$t('Delete')"
                                class="cursor-pointer"
                                :target="`delete-icon-${index}`"
                            />
                        </div>
                    </template>
                </b-tab>
            </b-tabs>
            <!-- <div class="divider"></div> -->
            <b-row v-if="activeText">
                <!-- 内容 -->
                <b-col :cols="12">
                    <b-form-group :label="$t('Content')">
                        <b-card class="border text-content"
                            :class="{
                                single: activeText.multi_line==0, 
                                multi: activeText.multi_line==1
                            }"
                        >
                            <!-- :style="{backgroundColor: activeText.bg_color}" -->
                            <!-- :style="{
                                    lineHeight: (activeText.line_height)+'px',
                                    color: activeText.text_color,
                                    fontSize: (activeText.font_size)+'px',
                                    letterSpacing:activeText.letter_spacing+'px'
                                }" -->
                            <div
                                
                                v-html="activeText.content"
                                v-if="activeText.content"
                            ></div>
                            <p v-else>{{$t('Please Add Text Content')}}</p>
                        </b-card>
                        <b-button slot="description" variant="primary" size="sm" @click="textEditSidebarVisible=true">{{$t('Edit')}}</b-button>
                    </b-form-group>
                </b-col>
                <!-- 字体大小 -->
                <b-col :cols="12" :md="4">
                    <b-form-group :label="$t('Font Size')">
                        <b-input-group append="px" class="input-group-merge">
                            <b-form-input
                                v-model="activeText.font_size"
                                trim
                                type="number"
                                :placeholder="$t('Please Enter...')"
                            />
                        </b-input-group>
                    </b-form-group>
                </b-col>
                <!-- 文字间距 -->
                <b-col :cols="12" :md="4">
                    <b-form-group :label="$t('Letter Spacing')">
                        <b-input-group append="px" class="input-group-merge">
                            <b-form-input
                                v-model="activeText.letter_spacing"
                                trim
                                type="number"
                                :placeholder="$t('Please Enter...')"
                            />
                        </b-input-group>
                    </b-form-group>
                </b-col>
                <!-- 行高 -->
                <b-col :cols="12" :md="4">
                    <b-form-group :label="$t('Line Height')">
                        <b-input-group append="px" class="input-group-merge">
                            <b-form-input
                                v-model="activeText.line_height"
                                trim
                                type="number"
                                :placeholder="$t('Please Enter...')"
                            />
                        </b-input-group>
                    </b-form-group>
                </b-col>
                <!-- 是否多行 -->
                <b-col :cols="12" :md="4">
                    <b-form-group :label="$t('Is Multiple Line')">
                        <v-select
                            v-model="activeText.multi_line"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            label="name"
                            :reduce="option=>option.id"
                            :searchable="false"
                            :clearable="false"
                            :options="mutiLineOptions"
                            :placeholder="$t('Please Select')"
                        />
                    </b-form-group>
                </b-col>
                <!-- 字体颜色 -->
                <b-col :cols="12" :md="4">
                    <b-form-group :label="$t('Text Color')">
                        <color-picker v-model="activeText.text_color"></color-picker>
                    </b-form-group>
                </b-col>
                <!-- 字体颜色 -->
                <b-col :cols="12" :md="4">
                    <b-form-group :label="$t('Background Color')">
                        <color-picker v-model="activeText.bg_color" @change="colorChange"></color-picker>
                    </b-form-group>
                </b-col>
                <!-- 滚动速度 -->
                <b-col :cols="12" :md="4">
                    <b-form-group :label="$t('Scroll Speed')">
                        <b-input-group append="ms" class="input-group-merge">
                            <b-form-input
                                v-model="activeText.speed"
                                trim
                                type="number"
                                :placeholder="$t('Please Enter...')"
                            />
                        </b-input-group>
                    </b-form-group>
                </b-col>
                <!-- 播放次数 -->
                <b-col :cols="12" :md="4">
                    <b-form-group :label="$t('Play Number')">
                        <b-form-input
                            v-model="activeText.play_number"
                            trim
                            type="number"
                            :placeholder="$t('Please Enter...')"
                            @input="$emit('update:list')"
                        />
                    </b-form-group>
                </b-col>
                <!-- 播放时长 -->
                <b-col :cols="12" :md="4">
                    <b-form-group :label="$t('Play Duration')">
                        <b-input-group append="s" class="input-group-merge">
                            <b-form-input
                                v-model="activeText.play_times"
                                trim
                                readonly
                                type="number"
                                :placeholder="$t('Please Enter...')"
                                @input="$emit('update:list')"
                            />
                        </b-input-group>
                    </b-form-group>
                </b-col>
            </b-row>
        </b-card>
        <div class="render-box">
            <div
                ref="textContent"
                class="render-text-box"
                :class="{
                    single: activeText.multi_line==0, 
                    multi: activeText.multi_line==1
                }"
                :style="{
                    lineHeight: (activeText.line_height)+'px',
                    color: activeText.text_color,
                    fontSize: (activeText.font_size)+'px',
                    letterSpacing:activeText.letter_spacing+'px'
                }"
                v-html="activeText.content"
            ></div>
        </div>
        <MaterialSelect :type="2" v-model="selectSidebarVisible" v-if="selectSidebarVisible" @select="onSelectText" />
        <TextEdit :content="activeText.content" @update:content="updateContent" v-model="textEditSidebarVisible" v-if="textEditSidebarVisible" />
        <!-- upload -->
		<upload
			v-if="uploadModalVisible"
			:is-active.sync="uploadModalVisible"
            :type="2"
			@refetch-data="addFileList"
		></upload>
    </div>
</template>
<script>
import { BCard, BCardText, BRow, BCol,BButton, BFormInput, BBadge, BTabs, BTab,BFormGroup, BInputGroup, BFormSpinbutton,BFormTextarea, BTooltip } from 'bootstrap-vue'
import draggable from "vuedraggable";
import vSelect from 'vue-select'
import { VueColorpicker } from 'vue-colorpicker-pop'
import WindowAttr from "./WindowAttr.vue"
import MaterialSelect from "./MaterialSelect.vue"
import TextEdit from './TextEdit.vue'
import Upload from "@/components/material/Upload.vue"
import {fullMaterialUrl} from "@/libs/utils"

export default {
	components: {
		BCard,
		BCardText,
		BRow, BCol,
		BButton,
		BFormInput,
		BBadge, BTabs, BTab,
        BFormGroup,BInputGroup, BFormSpinbutton,
        BFormTextarea, BTooltip,
        draggable,
        vSelect,
        'color-picker': VueColorpicker,
        WindowAttr,
        MaterialSelect,
        TextEdit,
        Upload
	},
    props: {
        winData: {
            type: Object,
            default: {}
        }
    },
	data () {
		return {
			textList: [],
            activeTextIndex: -1,
            isDragging: false,
            activeData: {},
            dragOptions: {
                animation: 300,
                group: "description",
                disabled: false,
                ghostClass: "ghost"
            },
            selectSidebarVisible: false,
            mutiLineOptions: [{
                id:0,
                name:"单行"
            },{
                id:1,
                name:"多行"
            }],
            textEditSidebarVisible: false,
            uploadModalVisible: false
		}
	},
    computed: {
        activeText () {
            if(this.activeTextIndex == -1) return null
            return this.textList[this.activeTextIndex]
        }
    },
    watch: {
        'activeText.content' (value) {
            this.computeTime()
        },
        'activeText.multi_line' (value) {
            this.computeTime()
        },
        'activeText.font_size' (value) {
            // console.log('activeText.font_size change', value)
            this.computeTime()
        },
        'activeText.letter_spacing' (value) {
            this.computeTime()
        },
        'activeText.line_height' (value) {
            // console.log('activeText.line_height change', value)
            this.computeTime()
        },
        'activeText.speed' (value) {
            // console.log('activeText.line_height change', value)
            this.computeTime()
        }
    },
    created () {
        this.activeData = JSON.parse(JSON.stringify(this.winData))
        this.textList = this.winData.txt_list
        if(this.textList.length>0){
            this.activeTextIndex = 0
        }
    },
    methods: {
        colorChange () {
            // console.log('colorChange')
        },
        computeTime () {
            this.$nextTick(()=>{
                let textContent = this.$refs.textContent
                let width = textContent.offsetWidth
                let height = textContent.offsetHeight
                // console.log('computeTime',width,height)
                // console.log(this.winData.width,this.winData.height,this.activeText.speed)
                let ms = 0
                if(this.activeText.multi_line==1){
                    ms = (height + parseInt(this.winData.height))*this.activeText.speed
                }else{
                    ms = (width+parseInt(this.winData.width))*this.activeText.speed
                }
                this.activeText.play_times = Math.ceil(ms/1000)
                this.$emit('update:list')
            })
            
        },
		getText (url){
            return new Promise((resolve,reject)=>{
                this.$http.get(url).then(res=>{
                    let words = res.data.split('\n').map(v => v.trim())
                    words = words.map(item=>{
                        return '<p>'+item+'</p>'
                    })
                    let text = words.join('')
                    resolve(text)
                }).catch(err=>{
                    // console.log(err)
                    reject(err)
                })
            })
			
		},
        uploadMaterial () {
            this.uploadModalVisible = true
        },
        addFileList (fileList) {
            fileList.forEach(item=>{
                this.addUrlText(item)
            })
        },
        selectText () {
            this.selectSidebarVisible = true
        },
        onSelectText (item) {
            // console.log(item)
            this.addUrlText(item)
        },
        async addUrlText (item) {
            const url = fullMaterialUrl(item.md5,item.suffix)
            let content = await this.getText(url)
            this.addText(content)
        },
        addText(content){
            content = content || ''
            this.textList.push({
                font_size: 30,
                letter_spacing: 0,
                line_height: 40,
                multi_line: 0,
                text_color: "#ffffff",
                bg_color: "rgba(0,0,0,0)",
                speed: 30,
                content: content,
                play_number: 1,
                play_times: 10
            })
            this.$nextTick(()=>{
                this.activeTextIndex = this.textList.length-1
            })
            this.$emit('update:list')
        },
        updateContent (content) {
            this.activeText.content = content
        },
        deleteText (index) {
            this.textList.splice(index,1)
            if(this.textList.length==0){
                this.activeTextIndex = -1
            }else if(index == this.activeTextIndex){
                this.activeTextIndex = 0
            }
            this.$emit('update:list')
        }
    }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
.text-item{
    position: relative;
    padding: 0 10px;
    .feather{
        position: absolute;
        top: -10px;
        right: -20px;
    }
}
.text-tabs{
    .nav-tabs{
        border-bottom: 1px solid #eee;
    }
}
.text-content{
    height: 100px;
    .card-body{
       padding: 0;
       overflow: auto;
       background: none;
       p{
           line-height: inherit;
       }
    }
    &.single{
        .card-body{
            justify-content: center;
            &>div{
                display: flex;
            }
            p{
                white-space: nowrap;
            }
        }
    }
    &.multi{
        .card-body{
            height: 100%;
            overflow: auto;
            // align-items: center;
            word-break: break-all;
        }
    }
}
.render-box{
    position: absolute;
    background: #000;
    opacity: 0;
    top: 0;
    height: 0;
    overflow: hidden;
    .render-text-box{
        &.single{
            display: flex;
        }
        p{
            line-height: inherit;
        }
    }
}
</style>
