import OSS from "ali-oss";
import { getOSS } from "@/libs/apis/serverApi"
import {getFileMd5} from "@/libs/utils"
import config from "@/libs/config"

export const useOSSUpload = () => {
    const { imgType,videoType,textType,audioType } = config.fileTypes
    const ossParams = {
        region: 'oss-cn-shenzhen',
        bucket: 'lingxingyu-cloud',
        accessKeyId: '',
        accessKeySecret: '',
        stsToken: '',
    }
    let client = null
    const getOSSParams  = () => {
        getOSS().then(res=>{
            let {AccessKeyId,AccessKeySecret, SecurityToken} = res
            ossParams.accessKeyId = AccessKeyId
            ossParams.accessKeySecret = AccessKeySecret
            ossParams.stsToken = SecurityToken

            console.log(ossParams)
            client = new OSS(ossParams);
        })
    }
    const getFileType = (suffix) => {
        // 图片格式
        if(imgType.indexOf(suffix)>=0){
            return 0
        }
        // 匹配 视频
        if(videoType.indexOf(suffix)>=0){
            return 1
        }
        // 匹配 文本
        if(textType.indexOf(suffix)>=0){
            return 2
        }
        // 匹配 音频
        if(audioType.indexOf(suffix)>=0){
            return 3
        }
    }
    const getFileName = (name) => {
        let temp = name.split('.')
        temp.splice(temp.length-1,1)
        return temp.join('')
    }


    const getVideoTime = (file) => {
        return new Promise((resolve, reject) => {
            let video = document.createElement("video");
            video.preload = 'metadata'
            video.src = URL.createObjectURL(file)
            video.onloadedmetadata = () => {
                resolve(video.duration)
                video = null
            };
        })
    }
    const getAudioTime = (file) => {
        return new Promise((resolve, reject) => {
            let audio = document.createElement("audio");
            audio.preload = 'metadata'
            audio.src = URL.createObjectURL(file)
            audio.onloadedmetadata = () => {
                resolve(audio.duration)
                audio = null
            };
        })
    }

    const getFileInfo = async ({file, onStart, onEnd}) => {
        onStart && onStart()
        let md5 = await getFileMd5(file)
        const suffix = file.name.split('.').pop()
        let type = getFileType(suffix)
        let fileInfo = {
            name: getFileName(file.name),
            suffix: suffix,
            md5: md5,
            size: file.size,
            type: type
        }
        if(type==1){
            fileInfo.hour = await getVideoTime(file)
        }else if(type==3){
            fileInfo.hour = await getAudioTime(file)
        }
        onEnd && onEnd()
        return fileInfo
    }

    const uploadFile = ({fileData, onProgress}) => {
        // const client = new OSS(ossParams);
        let fileName = fileData.md5+'.'+fileData.suffix
        return new Promise ((resolve,reject) => {
            client.multipartUpload(fileName, fileData.file,{
                headers: {
                    'x-oss-forbid-overwrite': true
                },
                progress: (progress) => {
                    onProgress && onProgress(progress)
                }
            }).then(res=>{
                resolve(res)
            }, err=>{
                reject (err)
            })
        })
        
    }

    return {
        getOSSParams,
        getFileInfo,
        uploadFile
    }
}

