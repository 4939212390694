var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"proeditor-container"},[_c('div',{staticClass:"d-flex flex-column flex-1"},[_c('div',{staticClass:"proeditor-toolbar d-flex align-items-center px-1"},[_c('div',{staticClass:"proeditor-layer flex-1"},[_c('div',{staticClass:"proeditor-title"},[_vm._v(_vm._s(_vm.$t('Program Name'))+"："+_vm._s(_vm.activeData.name)+" "),_c('small',[_vm._v("（尺寸：1920X1080）")])])]),_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"size":"20","icon":"XIcon"},on:{"click":_vm.closeAction}})],1),(_vm.program)?_c('div',{staticClass:"proeditor-main flex-1"},[_c('div',{staticClass:"d-flex flex-1 screen-main"},[_c('div',{staticClass:"screen-wrapper",style:({height: _vm.mainH+'px',width: _vm.mainW+'px'})},[_c('div',{staticClass:"screen",style:({
							width: (_vm.program.screen_w*_vm.realScale) +'px',
							minWidth: (_vm.program.screen_w*_vm.realScale)+'px',
							height: (_vm.program.screen_h*_vm.realScale)+'px'
						})},_vm._l((_vm.layerList),function(item,index){return _c('div',{key:index,staticClass:"screen-area",class:{active: _vm.activeLayer && _vm.activeLayer.id==item.id},style:({
									left: item.start_x*_vm.realScale+'px',
									top: item.start_y*_vm.realScale+'px',
									width: item.width*_vm.realScale+'px',
									height: item.height*_vm.realScale+'px',
									zIndex: item.z_index+1
								}),attrs:{"data-type":item.type,"id":item.id}},[(item.type==0)?_c('videoWindow',{ref:'node'+item.id,refInFor:true,attrs:{"winData":item,"scale":_vm.realScale,"musicData":_vm.musicData}}):_vm._e(),(item.type==1)?_c('imageWindow',{ref:'node'+item.id,refInFor:true,attrs:{"winData":item,"scale":_vm.realScale}}):_vm._e(),(item.type==2)?_c('textWindow',{ref:'node'+item.id,refInFor:true,attrs:{"winData":item,"scale":_vm.realScale}}):_vm._e(),(item.type==3)?_c('musicWindow',{ref:'node'+item.id,refInFor:true,attrs:{"winData":item,"scale":_vm.realScale}}):_vm._e()],1)}),0),_c('control',{ref:"control",attrs:{"layerList":_vm.layerList,"editType":0},model:{value:(_vm.activeLayer),callback:function ($$v) {_vm.activeLayer=$$v},expression:"activeLayer"}})],1)])]):_vm._e()])])}
var staticRenderFns = []

export { render, staticRenderFns }